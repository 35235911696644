import * as React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  HireTalentContainer,
  TalentContentContainer,
  HireTalentImageContainer,
  PerkContainer,
  CardContainer,
  StyledCard,
  Subtitle,
  Description,
  Icon,
  PerkIcon,
  MobileHeading,
  CaseStudyImage,
  StyledHeading,
  StyledHeadingLanding,
  PerkContainerTitle,
  StyledAnchor,
  StyledButton,
} from "./HireTechTalent.styles";
import { Paragraph } from "../Typography/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";

import HireTechTalentImage from "../../images/hiring-amico.png";
import CustomTeamImage from "../../images/content_icons/custom_team_buiding.png";
import AccelDevImage from "../../images/content_icons/customer_onboarding.png";
import ChangeMgmntImage from "../../images/content_icons/change_management.png";
import CostEffectiveImage from "../../images/content_icons/cost_effective.png";
import HiringImage from "../../images/content_icons/vendor.png";
import LeadershipImage from "../../images/content_icons/leadership.png";
import BackgroundImage from "../../images/content_icons/background.png";
import OnboardingImage from "../../images/content_icons/customer_onboarding.png";
import CustomerSupportImage from "../../images/content_icons/stakeholder_communication.png";
import CostEfectiveSupportImage from "../../images/content_icons/cost_effective.png";
import MovedImage from "../../images/moved.png";

const HireTechTalent = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <HireTalentContainer id="#hire-tech-talent">
      <TalentContentContainer>
        {isMobile && (
          <MobileHeading weight="semiBold">
            The Challenge: Optimizing Engineering Resources
          </MobileHeading>
        )}
        <HireTalentImageContainer
          src={HireTechTalentImage}
          alt="hire-tech-talent-you-need"
        />

        <PerkContainer>
          {!isMobile && (
            <StyledHeadingLanding weight="semiBold">
              The Challenge: Optimizing Engineering Resources
            </StyledHeadingLanding>
          )}
          <Paragraph>
            All too often, important projects around Client Onboarding & Support, Internal Tools
            and API Integrations get deprioritized because your team is focused on building core features.
          </Paragraph>
          <Paragraph>
            We will assist you in establishing the capacity to effectively address these aspects,
            setting you up for long-term success.
          </Paragraph>
        </PerkContainer>
      </TalentContentContainer>

      <PerkContainer>
        <PerkContainerTitle weight="semiBold">Our Solution</PerkContainerTitle>
        <CardContainer>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={CustomTeamImage} alt="icon" />
              Pre-screen and Initial Interviews
            </Subtitle>
            <Description>
              We'll take your job description(s) to find, screen and recruit
              remote talent to complement your existing engineering team(s)
            </Description>
          </StyledCard>

          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={AccelDevImage} alt="icon" />
              Customized Team Building
            </Subtitle>
            <Description>
              We'll work closely with your Engineering Head to understand your
              product requirements and recruit and manage an off-shore team to
              deliver an end-to-end solution
            </Description>
          </StyledCard>
        </CardContainer>
        <br />
        <br />
        <PerkContainerTitle weight="semiBold">What You Get</PerkContainerTitle>
        <CardContainer>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={ChangeMgmntImage} alt="icon" />
              Empowered In-House Engineers
            </Subtitle>
            <Description>
              By entrusting non-core tasks to our offshore team, your in-house
              engineers can dedicate their energies to driving innovation and
              advancing core features.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={CostEffectiveImage} alt="icon" />
              Cost-Effective Solutions
            </Subtitle>
            <Description>
              Our offshore talent provides budget-friendly alternatives to
              hiring additional in-house staff, enabling you to optimize
              resources without compromising quality.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={HiringImage} alt="icon" />
              Streamlined Hiring Process
            </Subtitle>
            <Description>
              Say goodbye to the cumbersome and time-consuming tasks of in-house
              hiring and onboarding. Our ready-to-deploy offshore team ensures
              swift integration and immediate productivity.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={LeadershipImage} alt="icon" />
              Experienced Leadership
            </Subtitle>
            <Description>
              Guided by seasoned software engineers, our offshore team
              seamlessly collaborates with your tech team, ensuring clear
              communication and alignment with your product vision.
            </Description>
          </StyledCard>
        </CardContainer>
        <StyledAnchor
          target="_blank"
          href="https://airtable.com/shrtXYrqddfQr5nbN"
        >
          <StyledButton
            label="I want to Hire Tech Talent"
            variant="primary"
            weight="semiBold"
          />
        </StyledAnchor>
      </PerkContainer>
      <br />
      <StyledHeading weight="semiBold">Case Study: Moved</StyledHeading>

      <CaseStudyImage src={MovedImage} alt="Moved" />

      <VerticalTimeline lineColor="#3252A0">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ border: "2px solid #3252A0", borderRadius: 8 }}
          contentArrowStyle={{ borderRight: "7px solid  #3252A0" }}
          iconStyle={{
            border: "2px solid #3252A0",
            color: "#fff",
            padding: isMobile ? 6 : 12,
            background: "#FFFFFF",
          }}
          icon={<Icon src={BackgroundImage} alt="icon" />}
        >
          <Subtitle weight="semiBold">Background</Subtitle>
          <Description>
            Moved is a prop-tech company that creates efficiency on-site by
            automating the move-inprocess, delivering an unforgettable
            experience for residents, and driving ancillary revenue to the
            clients’ portfolio.
          </Description>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ border: "2px solid #3252A0", borderRadius: 8 }}
          contentArrowStyle={{ borderRight: "7px solid  #3252A0" }}
          iconStyle={{
            border: "2px solid #3252A0",
            color: "#fff",
            padding: isMobile ? 6 : 12,
            background: "#FFFFFF",
          }}
          icon={<Icon src={OnboardingImage} alt="icon" />}
        >
          <Subtitle weight="semiBold">
            Challenge 1: Customer Onboarding
          </Subtitle>
          <Description>
            Moved’s onboarding process involved collecting information from
            their clients so that they could be configured onto the platform.
            Moved cannot realize their revenue until a client is onboarded onto
            the platform and began using the platform. However, the tech team
            does not have the bandwidth to onboard their customers.
          </Description>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ border: "2px solid #3252A0", borderRadius: 8 }}
          contentArrowStyle={{ borderRight: "7px solid  #3252A0" }}
          iconStyle={{
            border: "2px solid #3252A0",
            color: "#fff",
            padding: isMobile ? 6 : 12,
            background: "#FFFFFF",
          }}
          icon={<Icon src={CustomerSupportImage} alt="icon" />}
        >
          <Subtitle weight="semiBold">Challenge 2: Tech Client Support</Subtitle>
          <Description>
            Moved’s growing but small tech team also did not have the bandwidth
            to provide customer support to platform users. Hiring in-house
            customer support team members was too costly.
          </Description>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ border: "2px solid #3252A0", borderRadius: 8 }}
          contentArrowStyle={{ borderRight: "7px solid  #3252A0" }}
          iconStyle={{
            border: "2px solid #3252A0",
            color: "#fff",
            padding: isMobile ? 6 : 12,
            background: "#FFFFFF",
          }}
          icon={<Icon src={CostEfectiveSupportImage} alt="icon" />}
        >
          <Subtitle weight="semiBold">alphathesis’s Impact</Subtitle>
          <Description>
            alphathesis provided solutions to boost Moved’s customer support and
            onboarding infrastructure.
          </Description>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </HireTalentContainer>
  );
};

export default HireTechTalent;
