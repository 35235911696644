import * as React from "react";
import Layout from "../../components/Layout/Layout";

import HireTechTalent from "../../components/HireTechTalent";
import Footer from "../../components/Footer";
import ScrollHandler from "../../components/ScrollHander";

const HireTechTalentPage = () => (
  <>
    <ScrollHandler />
    <Layout>
      <HireTechTalent />
      <Footer />
    </Layout>
  </>
);

export default HireTechTalentPage;
